<template>
    <div class="common-layout">
        <el-container>
            <el-header height="50px">
                <div class="flex_row_between_center">
                    <div class="flex_row_start_center">
                        <div class="flex_row_center_center img_logo_part">
                            <img class="img_logo" :src="imLogo" alt @click='goHome' />
                        </div>
                        <span class="header_title">商户-客服助手</span>
                    </div>
                    <div class="header_right">
                        <span>{{storeInfo.data.storeName}}——{{storeInfo.data.vendorName}}</span>
                        <span class="login_out" @click="loginOut">【退出】</span>
                    </div>
                </div>
            </el-header>
            <el-container>
                <el-aside width="150px">
                    <router-link :to="{path:'/service'}" class="sld_left_nav_item">
                        <i class="el-icon-postcard icon"></i>
                        <span class="text">对话平台</span>
                    </router-link>

                    <router-link :to="{path:'/history'}" class="sld_left_nav_item">
                        <i class="el-icon-chat-line-square icon"></i>
                        <span class="text">聊天记录</span>
                    </router-link>

                    <router-link :to="{path:'/autoReply'}" class="sld_left_nav_item">
                        <i class="el-icon-chat-dot-round icon"></i>
                        <span class="text">欢迎语&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </router-link>
                    <router-link :to="{path:'/quickReply'}" class="sld_left_nav_item">
                        <i class="el-icon-chat-line-round icon"></i>
                        <span class="text">快捷回复</span>
                    </router-link>
                    <router-link :to="{path:'/casualQues'}" class="sld_left_nav_item">
                        <i class="el-icon-collection icon"></i>
                        <span class="text">常见问题</span>
                    </router-link>
                    <router-link :to="{path:'/setting'}" class="sld_left_nav_item">
                        <i class="el-icon-set-up icon"></i>
                        <span class="text">聊天设置</span>
                    </router-link>

                </el-aside>
                <el-container :style="'max-width:'+clientWidth+'px'" id="elContainer">
                    <el-main style="margin:10px">
                        <router-view></router-view>
                    </el-main>
                    <!--                    <el-footer>Footer</el-footer>-->
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import { ref, reactive, getCurrentInstance, onMounted } from "vue";
    import '@/style/base.scss'
    import { useRouter } from "vue-router";
    import { useStore } from "vuex";
    import { storeUrl } from '@/utils/config'
    import axios from 'axios'

    export default {
        name: 'Home',
        components: {},
        setup() {
            const store = useStore();
            const { proxy } = getCurrentInstance();
            const isCollapse = ref(false);
            const imLogo = require("@/assets/im_logo.png");
            const router = useRouter();
            const storeInfo = reactive({ data: {} });//店铺基本信息
            const clientWidth = ref(0)
            const handleOpen = (key, keyPath) => {
                console.log(key, keyPath);
            }
            const handleClose = (key, keyPath) => {
                console.log(key, keyPath);
            }

            //退出登录功能
            const loginOut = () => {
                //调用商户的退出登录接口
                let sld_refresh_token = localStorage.getItem('sld_refresh_token');
                let param = new FormData();
                param.append('grant_type', 'refresh_token');
                param.append('refresh_token', sld_refresh_token);
                axios.post(storeUrl + "v3/sellerLogin/oauth/logout", param).then(res => {
                    console.log(435345345, res)
                }, err => {
                    console.log(err)
                })

                //清除全部缓存
                localStorage.clear();
                //跳转登录页
                let des_url = window.location.href;
                if (des_url.indexOf('redirect=') != -1) {
                    des_url = des_url.substring(0, des_url.indexOf('redirect=') - 1);
                }
                window.location.href = storeUrl + '/user/login?redirect=' + encodeURIComponent(des_url);
                window.reload();
            }
            const goHome = () => {
                router.push({
                    path: "/service",
                });
            }
            //获取店铺的基本信息
            const getStoreBaseInfo = () => {
                proxy.$get('v3/seller/seller/store/indexStoreInfor').then(res => {
                    if (res.state == 200) {
                        storeInfo.data = res.data;
                        store.commit("updateStoreInfo", res.data);
                    }
                })
            }
            //获取声音的设置
            const getVoiceSetting = () => {
                proxy.$post('v3/helpdesk/seller/sound/setting/isOpen').then(res => {
                    store.commit("updateMsgVoiceSetting", res.data.isOpen ? true : false);
                })
            }
            getStoreBaseInfo();
            getVoiceSetting();

            router.beforeEach((to) => {
                if (to.name != 'service') {
                    proxy.$socket.disconnect()
                } else {
                    proxy.$socket.connect()
                }
            })

            onMounted(() => {
                clientWidth.value = document.body.offsetWidth || document.documentElement.offsetWidth
            })

            return { isCollapse, handleOpen, handleClose, imLogo, goHome, loginOut, storeInfo, clientWidth }
        }
    }
</script>
<style lang="scss" scoped>
    .sld_left_nav_item {
        width: 100%;
        display: block;
        color: #fff;
        height: 50px;
        line-height: 50px;
        background: #1D2647;

        .icon {
            color: #fff;
            font-size: 16px;
            margin-right: 10px;
            font-weight: bold;
        }

        .text {
            color: #fff;
            font-size: 14px;
        }

        &.router-link-active {
            background: #fff;

            .icon {
                color: #1D2647;
            }

            .text {
                color: #1D2647;
            }
        }
    }

    .el-header,
    .el-footer {
        background-color: #fff;
    }

    .el-header {
        padding-left: 0 !important;
        padding-right: 10px;

        .img_logo_part {
            width: 150px;
            height: 50px;
            background: #1D2647;

            .img_logo {
                width: 37px;
                cursor: pointer;
            }
        }

        .header_title {
            display: inline-block;
            height: 50px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #114A97;
            line-height: 50px;
            margin-left: 20px;
        }

        .header_right {
            .login_out {
                cursor: pointer;

                &:hover {
                    color: #1c94fa;
                }
            }
        }
    }

    .el-aside {
        color: #fff;
        text-align: center;
        background-color: #1D2647;
        padding-top: 3px;
    }

    .el-main {
        background-color: #fff;
        height: calc(100vh - 70px);
        padding: 15px;
    }

    .el-container {
        background: #f0f2f5;
    }

    .el-menu {
        height: calc(100vh - 50px);
        background-color: #1D2647;
    }

    .el-menu-item,
    .el-menu-item i {
        color: #fff;
    }

    .el-menu-item:hover {
        background-color: #fff;

        .el-menu-item,
        .el-menu-item i {
            color: #1D2647;
        }
    }

    .el-menu-item.is-active {
        color: #1D2647;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 150px;
        min-height: 400px;
    }
</style>